'use client';

import React, { createContext, useCallback, useContext, useState } from 'react';
import { DisplayUser } from '@/lib/api';
import { API_BASE_URL } from '@/app/common';

interface UserDetailsContextType {
  userDetails: DisplayUser | null;
  reloadUserDetails: () => Promise<void>;
  reloadUserDetailsAndWait: () => Promise<DisplayUser | null>;
}

const UserDetailsContext
  = createContext<UserDetailsContextType | undefined>(undefined);

// InitialUserDetails is provided by layout.tsx, which sets it from the
// headers passed in by the middleware.  TODO: reconsider this...
interface UserDetailsProviderProps {
  children: React.ReactNode;
  initialUserDetails: DisplayUser | null;
}

export const UserDetailsProvider: React.FC<UserDetailsProviderProps>
    = ({ children, initialUserDetails }) => {
  const [userDetails, setUserDetails]
    = useState<DisplayUser | null>(initialUserDetails);

  const reloadUserDetailsAndWait = useCallback(async (): Promise<DisplayUser | null> => {
    try {
      const auth_check_url = API_BASE_URL + '/user/info';
      const response = await fetch(auth_check_url);
      if (!response.ok) {
        setUserDetails(null);
        throw new Error('Failed to fetch user info');
      } else {
        const deets = await response.json();
        setUserDetails(deets);
        return deets;
      }
    } catch (error) {
      setUserDetails(null);
      console.error('Error reloading user details:', error);
      return null;
    }
  }, []);

  const reloadUserDetails = useCallback(async () => {
    await reloadUserDetailsAndWait();
  }, [reloadUserDetailsAndWait]);

  return (
    <UserDetailsContext.Provider value={{ userDetails, reloadUserDetails, reloadUserDetailsAndWait }}>
      {children}
    </UserDetailsContext.Provider>
  );
};

export const useUserDetails = () => {
  const context = useContext(UserDetailsContext);
  if (context === undefined) {
    throw new Error('useUserDetails must be used within a UserDetailsProvider');
  }
  return context;
};
