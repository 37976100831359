//////////////////////////////////////////////////////////////////////////////
//
// Shared functions and components
//
// TODO: split this up and move to separate files in the appropriate directories

"use client"  /* TODO: figure out what can be server-side */

import React, { useState, useEffect, useCallback, useRef, ReactNode } from "react";
import Link from 'next/link';
import Image from "next/image";
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { DisplayConceptRef, DocNode, DisplayUserFollow, GuideJsonResp, ProductJsonResp, updateUserCommunities, updateUserFollows, UpdateUserFollowsRequest, urlFetcher, DisplayUser } from '@/lib/api';
import ReactMarkdown, { Components } from 'react-markdown';
import { SelectItem, TopicSelector } from '../components/TopicSelector';
import { DisplayMentionedConcept } from "../lib/api";
import { ComposeBox } from '../components/ComposeBox';
import { decode } from "punycode";

// Using proxying for now for both normal API requests as well as websockets.
// This takes some configuration to set up.
export const API_BASE_URL = "/api";
export const WS_API_BASE_URL = "/wsapi";


//////////////////////////////////////////////////////////////////////////////
//
// General purpose helper functions
//

export function topic_url(cref: string): string {
    return `/guide/${cref}`;
}

export function sku_url(cref: string): string {
    let encoded = encodeURIComponent(cref);
    return `/products/${encoded}`;
}

export function strip_en(s: string): string {
    return s.replace("@en:", "");
}

// TODO: this should be more principled...., also consolidate with User::is_llm()
// on the rust side.
export function isLLM(userId: string): boolean {
  const llmIds = ["gpt-4o", "mixtral8x7B", "llama31-70B"];
  return llmIds.includes(userId);
}


//////////////////////////////////////////////////////////////////////////////
//
// Simple shared or general purpose components
//

export function UserLink({ username }: { username: string }) {
  return isLLM(username)
    ? <>{username}</>
    : <Link href={`/citizen/${encodeURIComponent(username)}`}>
      {username}
      </Link>
}

export function showExploreLinks(cdef: Record<string, any>): boolean {
  return cdef.sup?.length > 0 || cdef.sub?.length > 0;
}
export function ExploreLinks({cdef}: {cdef: Record<string, any>}) {
    return (
        <div className="content explore-links">
            <div className="explore-section">
                {cdef.sup?.length > 0 && <h3>Broader:</h3>}
                <ul className="no-bullets">
                    {/* TODO: taking the arbitrary first n; should score */}
                    {cdef.sup?.slice(0,5).map((sup: string) => (
                        <li key={sup}>
                            <Link href={topic_url(sup)}>
                                {strip_en(sup)}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="explore-section">
                {cdef.sub?.length > 0 && <h3>Subcategories:</h3>}
                <ul className="no-bullets">
                    {/* TODO: taking the arbitrary first n; should score */}
                    {cdef.sub?.slice(0,20).map((sub: string) => (
                        <li key={sub}>
                            <Link href={topic_url(sub)}>
                                {strip_en(sub)}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}


export function GuideSummaryContent({guide}: {guide: GuideJsonResp}) {
  return (
    <div>
      <ReactMarkdown>
        {guide ? guide.summary : ""}
      </ReactMarkdown>
    </div>
  );
}



//////////////////////////////////////////////////////////////////////////////
//
// A general purpose container for content
//

export function ContentCard({title, children, collapsible, initialState = true}:
    {title?: string | ReactNode, children: React.ReactNode, collapsible: boolean, initialState?: boolean}) {
  const [isOpen, setIsOpen] = useState(initialState);

  let content: React.ReactNode;
  if (collapsible) {
    if (React.Children.count(children) !== 2) {
      return null;
    }
    let [summary, full] = React.Children.toArray(children);
    content = isOpen ? full : summary;
  } else {
    content = children;
  }

  return (
    <div className="content">
      {title && <h3 className="content-title">{title}</h3>}
      <div className="content-wrapper">
        <div className={`collapsible__content ${isOpen ? 'collapsible__content--open' : ''}`}>
          {content}
        </div>
        {collapsible && (
          <div className="toggle-wrapper">
            <button 
              className="collapsible__toggle"
              onClick={() => setIsOpen(!isOpen)}
            >
              {isOpen ? <FaChevronUp /> : <FaChevronDown />}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export function ToggleContainer({title, children, onClose}:
        {title?: string, children: React.ReactNode, onClose: () => void}) {
    return (
        <div className="toggle-container">
            <div className="toggle-container-content">
                {title && <h2>{title}</h2>}
                {children}
            </div>
        </div>
    );
}

//////////////////////////////////////////////////////////////////////////////
//
// Components and functions for rendering documents
//

export function DocCard({ doc, depth = 0, isMainDoc = false, isLinear = false, mutate }: { doc: DocNode; depth?: number; isMainDoc?: boolean; isLinear: boolean; mutate: () => void }) {
  const [processingStatus, setProcessingStatus] = useState<{ message: string | null, animate: boolean }>({ message: null, animate: false });
  const cardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isMainDoc && cardRef.current) {
      cardRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [isMainDoc]);

  useEffect(() => {
    // When someone posts, they are redirected to the URL for that post;
    // at the same time the backend should be analyzing the new post to
    // determine whether the LLM will post a response.  This websocket
    // tries to watch the status of that analysis.
    if (!isMainDoc) {
      return;  // Only listen for updates on the main document
    }

    // const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
    // const socket = new WebSocket(`${protocol}//${window.location.hostname}:3001/doc/updates/${doc.key}`);
    const socket = new WebSocket(`${WS_API_BASE_URL}/doc/updates/${doc.key}`);
    
    socket.onopen = (event) => {
      // console.log('WebSocket connection opened:', event);
    };

    socket.onmessage = (event) => {
      let timestamp = new Date().toISOString();

      switch (event.data) {
        case 'Reading':
          setProcessingStatus({ message: 'Reading post', animate: true });
          break;
        case 'ReadingThread':
          setProcessingStatus({ message: 'Reading thread', animate: true });
          break;
        case 'Abstain':
          setProcessingStatus({ message: 'Decided not to reply', animate: false });
          socket.close();
          break;
        case 'Researching':
          setProcessingStatus({ message: 'Researching', animate: true });
          break;
        case 'Recommending':
          setProcessingStatus({ message: 'Recommending', animate: true });
          break;
        case 'Answering':
          setProcessingStatus({ message: 'Answering post', animate: true });
          break;
        case 'Answered':
          setProcessingStatus({ message: 'Answered', animate: false });
          break;
        case 'Replied':
          setProcessingStatus({ message: 'Replied', animate: false });
          break;
        case 'Completed':
          setProcessingStatus({ message: null, animate: false });  // Clear status
          mutate();  // Reload the documents
          socket.close();
          break;
        case 'Failed':
          setProcessingStatus({ message: 'Internal error while replying', animate: false });
          socket.close();
          break;
        default:
          console.log("Unrecognized status: ", event.data);
          setProcessingStatus({ message: null, animate: false });  // Clear status
      }
    };

    socket.onerror = (error) => {
      // TODO: we get this error for every page load that isn't on a doc that's currently
      // being processed.  Not really an error.  Can we fix the backend to send some ack
      // code or something instead of an error?
      console.error('No WebSocket connection made:', error);
    };

    socket.onclose = (event) => {
      // console.log('WebSocket connection closed:', event);
    };

    return () => {
      socket.close();
    };
  }, [doc.key, isMainDoc, mutate]);

  const indentLevel = isLinear ? 0 : depth;

  return (
    <div ref={cardRef} style={{ marginLeft: `${indentLevel * 30}px`, position: 'relative' }}>
      {isMainDoc && (
        <span style={{
          position: 'absolute',
          left: '-25px',
          top: '50%',
          transform: 'translateY(-50%)',
        }}>
         ▶
        </span>
      )}

      <ContentCard collapsible={false}>
        <div className="flex-item">
          <div className="doc-provenance">
            <span className="doc-creator">{doc.provenance.creator}</span>
            <span className="doc-date">
              {new Date(doc.provenance.createdon).toLocaleString()}
              &nbsp; &nbsp;
              <Link href={"/doc/" + doc.key} className="no-decoration">
                🔗
              </Link>
            </span>
          </div>


          <DecoratedMarkdown concepts={doc.concepts}>
            {doc.text}
          </DecoratedMarkdown>

          <ComposeBox
            onClose={() => {}}
            topic={doc.topic.cref}
            citationKey={doc.key}
          />
          {processingStatus.message && (
            <div 
              className="processing-status"
              data-ellipsis={processingStatus.animate ? '' : undefined}
            >
              Processing: {processingStatus.message}
            </div>
          )}
        </div>
      </ContentCard>
    </div>
  );
}

export function DecoratedMarkdown({ children, concepts }: { children: string, concepts: DisplayMentionedConcept[] }) {
  return (
    <ReactMarkdown components={{
      p: ({ children }) => <p>{decorateMarkdownLinksWImages(children, concepts)}</p>,
      h1: ({ children }) => <h1>{decorateMarkdownLinksWImages(children, concepts)}</h1>,
      h2: ({ children }) => <h2>{decorateMarkdownLinksWImages(children, concepts)}</h2>,
      h3: ({ children }) => <h3>{decorateMarkdownLinksWImages(children, concepts)}</h3>,
      h4: ({ children }) => <h4>{decorateMarkdownLinksWImages(children, concepts)}</h4>,
      h5: ({ children }) => <h5>{decorateMarkdownLinksWImages(children, concepts)}</h5>,
      h6: ({ children }) => <h6>{decorateMarkdownLinksWImages(children, concepts)}</h6>,
      li: ({ children }) => <li>{decorateMarkdownLinksWImages(children, concepts)}</li>,
      strong: ({ children }) => <strong>{decorateMarkdownLinksWImages(children, concepts)}</strong>,
      em: ({ children }) => <em>{decorateMarkdownLinksWImages(children, concepts)}</em>,
    }}>
      {rewriteMarkdownLinks(children)}
    </ReactMarkdown>
  );
}

export function rewriteMarkdownLinks(text: string): string {
  const linkRegex = /\[([^\]]+)\]\(cref:([^)"]+)(\s+"[^"]*")?\)/g;
  
  return text.replace(linkRegex, (match, linkText, href, title = '') => {
    if (href.startsWith('@')) {
      return `[${linkText}](${topic_url(href)}${title})`;
    } else if (href.startsWith('/')) {
      return `[${linkText}](${sku_url(href)}${title})`;
    }
    return match; // If it doesn't match the expected format, leave it unchanged
  });
}

export function decorateMarkdownLinksWImages(node: React.ReactNode, concepts: DisplayMentionedConcept[]): React.ReactNode {
  return React.Children.map(node, child => {
    if (React.isValidElement(child)) {
      if (child.type === 'a') {
        const anchorChild = child as React.ReactElement<React.AnchorHTMLAttributes<HTMLAnchorElement>>;
        const href = anchorChild.props.href;

        // This is kinda ugly: since ReactMarkdown filters invalid href elements, we
        // have to rewrite pseudo-URLs to real URLs prior to calling it.  Since it's
        // easier to insert images *after* calling it, it means we can't match on the
        // actual cref IDs since they've been translated to actual URLs -- so here we
        // need to match on the actual URLs.
        const matchingConcept = concepts.find(c => c.dcref && (topic_url(c.dcref.cref) == href || sku_url(c.dcref.cref) == href));
        if (matchingConcept) {
          const updatedChild = React.cloneElement(anchorChild, {
            title: matchingConcept.description,
            'aria-label': matchingConcept.description
          });
          
          if (matchingConcept.img_url) {
            return (
              <>
                {updatedChild}
                <span style={{
                  float: 'right',
                  marginLeft: '10px',
                  marginBottom: '10px',
                  width: '100px',
                  height: '100px',
                  position: 'relative',
                  display: 'inline-block'
                }}>
                  <Image src={matchingConcept.img_url} alt={matchingConcept.mention} fill />
                </span>
              </>
            );
          }
          
          return updatedChild;
        }
      } else {
        const recurseChild = child as React.ReactElement;
        if (recurseChild.props.children) {
          return React.cloneElement(recurseChild, {
            children: decorateMarkdownLinksWImages(recurseChild.props.children, concepts)
          });
        }
      }
    }
    return child;
  });
}



//////////////////////////////////////////////////////////////////////////////
//
// Functions for accessing and updating info about the current logged-in user
// from the backend
// 

export function followsMap(follows: DisplayUserFollow[] | null): Map<string, DisplayConceptRef[]> {
    let followsMap = new Map<string, DisplayConceptRef[]>();
    if (follows != null) {
      follows.forEach((follow) => {
          followsMap.set(follow.username, follow.topics);
      });
    }
    return followsMap;
};

// use useSWR here?
export async function getUserDetails(
  username: string
) : Promise<DisplayUser> {
    // TODO can this be fetchURL?
    let resp = await fetch(`${API_BASE_URL}/user/get_details/${encodeURIComponent(username)}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    });
    if (!resp.ok) {
        throw new Error("Error getting user details: " + resp.statusText);
    }

    let json_data = await resp.json();
    return json_data;
}

export async function addUserDetailsCommunity(username: string, topic: string): Promise<DisplayUser> {
  return updateUserCommunities(username, { add_communities: [topic] } );
}

export async function remUserDetailsCommunity(username: string, topic: string): Promise<DisplayUser> {
  return updateUserCommunities(username, { rem_communities: [topic] } );
}

// TODO: should the caller pass in all this info, or just the username, and let
// the component do the loading?  Or, should we pass in a structured follow object?
interface FollowButtonProps {
  authUsername: string;
  followUsername: string;
  currentlyFollowing: boolean;
  topics: DisplayConceptRef[];
  onFollowUpdate?: (updatedUserDetails: DisplayUser) => void;
}

export function renderTimestamp(timestamp: string): string {
  const date = new Date(timestamp);
  const now = new Date();
  
  if (date.toDateString() === now.toDateString()) {
    const now = new Date();
    const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);
    
    let formattedDiff;
    if (diffInSeconds < 60) {
        formattedDiff = `${diffInSeconds}s`;
    } else if (diffInSeconds < 3600) {
        const diffInMinutes = Math.floor(diffInSeconds / 60);
        formattedDiff = `${diffInMinutes}m`;
    } else {
        const diffInHours = Math.floor(diffInSeconds / 3600);
        formattedDiff = `${diffInHours}h`;
    }
    
    return formattedDiff;
  }
  
  if (date.getFullYear() === now.getFullYear()) {
    return date.toLocaleDateString([], { month: 'short', day: 'numeric' });
  }
  
  return date.toLocaleDateString([], { year: 'numeric', month: 'short', day: 'numeric' });
}

export function FollowButton({ authUsername, followUsername, currentlyFollowing, topics, onFollowUpdate }: FollowButtonProps) {
  // Need to init selectedTopics from a backend call

  const [showModal, setShowModal] = useState(false);
  const [selectedTopics, setSelectedTopics] = useState<DisplayConceptRef[]>(topics);

  const handleOpenModal = () => {
    setShowModal(true);
    setSelectedTopics(topics);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedTopics([]);
  };

  const handleFollowUnfollow = async () => {
    try {
      let request: UpdateUserFollowsRequest = {
        username: authUsername,
        follow: followUsername,
        action: currentlyFollowing ? "Unfollow" : "Follow",
        topics: currentlyFollowing ? [] : selectedTopics.map(topic => topic.cref),
      };
        
      let updatedUserDetails: DisplayUser = await updateUserFollows(authUsername, request);
      if (onFollowUpdate) {
        onFollowUpdate(updatedUserDetails);
      }
      handleCloseModal();
    } catch (error) {
      console.error('Error following/unfollowing user:', error);
    }
  };

  const updateFollowTopics = async () => {
    try {
      let request: UpdateUserFollowsRequest = {
        username: authUsername,
        follow: followUsername,
        action: "Update",
        topics: selectedTopics.map(topic => topic.cref),
      };
        
      let updatedUserDetails: DisplayUser = await updateUserFollows(authUsername, request);
      if (onFollowUpdate) {
        onFollowUpdate(updatedUserDetails);
      }
      handleCloseModal();
    } catch (error) {
      console.error('Error updating follow of user:', error);
    }
  }

  const handleTopicSelect = (selectItem: SelectItem) => {
    let topic: DisplayConceptRef = {
      cref: selectItem.value,
      display: selectItem.label,
    };
    setSelectedTopics(prevTopics => [...prevTopics, topic]);
  };

  return (
    <>
      <button onClick={handleOpenModal} className="follow-button">
        {currentlyFollowing ? `Following` : `Follow`}
      </button>
      {showModal && (
        <div className="modal-overlay">
          <div className="modal">
            <div className="modal-content">

              <h2>{currentlyFollowing ? 'Unfollow' : 'Follow'}
                {' '}{followUsername}
                {' '}{currentlyFollowing ? 'or update topics:' : 'on:' }
              </h2>

              {selectedTopics.length == 0
              ? <ul>
                <li><i>All topics</i></li>
              </ul>
              : (
                <div className="selected-topics">
                  <ul>
                    {selectedTopics.map((topic, index) => (
                      <li key={index} className="selected-topic-item">
                        <span>{topic.display}</span>
                        <button 
                          className="remove-topic-button" 
                          onClick={() => setSelectedTopics(prevTopics => prevTopics.filter((_, i) => i !== index))}
                        >
                          ✕
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              <div>
                {selectedTopics.length == 0
                  ? 'Or select topics:'
                  : 'Add topic:'}
                <TopicSelector instanceId='follow-button-topic-selector' onChangeFn={(topic: SelectItem) => handleTopicSelect(topic)} />
              </div>

              <div className="modal-buttons">
                { currentlyFollowing && (
                  <button onClick={updateFollowTopics} className="confirm-button">
                    Update Topics
                  </button>
                ) }
                <button onClick={handleFollowUnfollow} className="confirm-button">
                  {currentlyFollowing ? 'Unfollow' : 'Follow'}
                </button>
                <button onClick={handleCloseModal} className="cancel-button">
                  Cancel
                </button>
              </div>

            </div>
          </div>
        </div>
      )}
    </>
  );
}

interface JoinButtonProps {
  authUsername: string;
  community: DisplayConceptRef;
  currentlyJoined: boolean;
  onJoinUpdate?: (updatedUserDetails: DisplayUser) => void;
}

// TODO: integrate this with the user Follow mechanism and button
export function JoinButton({ authUsername, community, currentlyJoined, onJoinUpdate }: JoinButtonProps) {
  const handleJoin = async () => {
    try {
      let updatedUserDetails: DisplayUser = currentlyJoined ?
        await remUserDetailsCommunity(authUsername, community.cref) :
        await addUserDetailsCommunity(authUsername, community.cref);
      if (onJoinUpdate) {
        onJoinUpdate(updatedUserDetails);
      }
    } catch (error) {
      console.error('Error joining community:', error);
    }
  };

  return (
    <button onClick={handleJoin} className="join-button">
      {currentlyJoined ? `Unfollow` : `Follow`}
    </button>
  );
}
