import React, { useState, useRef, useEffect, KeyboardEvent } from 'react';
import { useRouter } from 'next/navigation';
import ReactMarkdown from 'react-markdown';
import { API_BASE_URL } from '../app/common';
import { useUserDetails } from '@context/UserDetailsContext';

interface ComposeBoxProps {
    onClose: () => void;
    topic: string;
    citationKey?: string | null;
    placeholder?: string | null;
}

export function ComposeBox({ onClose, topic, citationKey, placeholder }: ComposeBoxProps) {
    const [content, setContent] = useState('');
    const [isExpanded, setIsExpanded] = useState(false);
    const [isPosting, setIsPosting] = useState(false);
    const [showUserSuggestions, setShowUserSuggestions] = useState(false);
    const [userSuggestions, setUserSuggestions] = useState<string[]>([]);
    const [cursorPosition, setCursorPosition] = useState(0);
    const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(0);
    const router = useRouter();
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const { userDetails } = useUserDetails();

    if (!placeholder) {
        placeholder = 'Reply...';
    }

    useEffect(() => {
        if (showUserSuggestions && textareaRef.current) {
            const textarea = textareaRef.current;
            const { selectionStart, selectionEnd } = textarea;
            
            if (selectionStart === selectionEnd) {
                const { scrollLeft, scrollTop } = textarea;
                const textBeforeCursor = content.substring(0, selectionStart);
                const lines = textBeforeCursor.split('\n');
                const currentLineIndex = lines.length - 1;
                const currentLineText = lines[currentLineIndex];
                
                const textareaStyles = window.getComputedStyle(textarea);
                const lineHeight = parseInt(textareaStyles.lineHeight);
                const fontSize = parseInt(textareaStyles.fontSize);
                const paddingLeft = parseInt(textareaStyles.paddingLeft);
                const paddingTop = parseInt(textareaStyles.paddingTop);
                
                const charWidth = fontSize * 0.6; // Approximate character width
                const left = paddingLeft + (currentLineText.length * charWidth) - scrollLeft;
                const top = paddingTop + ((currentLineIndex + 2) * lineHeight) - scrollTop;
                
                const suggestionBox = document.getElementById('user-suggestions');
                if (suggestionBox) {
                    const rect = textarea.getBoundingClientRect();
                    suggestionBox.style.position = 'absolute';
                    suggestionBox.style.top = `${rect.top + top + lineHeight}px`; // Add one more lineHeight
                    suggestionBox.style.left = `${rect.left + left}px`;
                    suggestionBox.style.maxHeight = `${window.innerHeight - (rect.top + top + lineHeight) - 20}px`; // Adjust maxHeight
                }
            }
        }
    }, [showUserSuggestions, content, cursorPosition]);

    const containsMarkdown = (text: string): boolean => {
        // Regular expression to match common Markdown syntax
        const markdownRegex = /(\*\*|__|\*|_|`|#|\[.*\]\(.*\)|!\[.*\]\(.*\)|\n\s*[-*+]\s|\n\s*\d+\.\s|\n\s*>\s)/;
        return markdownRegex.test(text);
    };

    const handlePost = async () => {
        setIsPosting(true);
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ content, topic }),
            };

            if (citationKey) {
                requestOptions.body = JSON.stringify({ content, topic, citation: citationKey });
            }

            const response = await fetch(`${API_BASE_URL}/doc/new`, requestOptions);
            if (!response.ok) {
                throw new Error('Failed to post content');
            }

            const responseData = await response.json();
            console.log('New doc key:', responseData.doc_key);

            // Handle successful post: redirect to the new doc page
            router.push(`/doc/${responseData.doc_key}`);
        } catch (error) {
            console.error('Error posting content:', error);
            // Handle error (e.g., show an error message to the user)
        } finally {
            setIsPosting(false);
        }
    };

    const showPreview = containsMarkdown(content);

    const handleTextareaFocus = () => {
        setIsExpanded(true);
    };

    const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newContent = e.target.value;
        setContent(newContent);
        setCursorPosition(e.target.selectionStart);

        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }

        const lastAtSymbolIndex = newContent.lastIndexOf('@', e.target.selectionStart);
        if (lastAtSymbolIndex !== -1 && e.target.selectionStart > lastAtSymbolIndex) {
            const query = newContent.slice(lastAtSymbolIndex + 1, e.target.selectionStart).toLowerCase();
            const suggestions = userDetails?.following
                .filter(follow => follow.username.toLowerCase().startsWith(query))
                .map(follow => follow.username);
            setUserSuggestions(suggestions || []);
            setShowUserSuggestions(suggestions && suggestions.length > 0 || false);
            setSelectedSuggestionIndex(0);
        } else {
            setShowUserSuggestions(false);
        }
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
        if (showUserSuggestions) {
            if (e.key === 'ArrowDown') {
                e.preventDefault();
                setSelectedSuggestionIndex((prevIndex) =>
                    (prevIndex + 1) % userSuggestions.length
                );
            } else if (e.key === 'ArrowUp') {
                e.preventDefault();
                setSelectedSuggestionIndex((prevIndex) =>
                    (prevIndex - 1 + userSuggestions.length) % userSuggestions.length
                );
            } else if (e.key === 'Enter' && userSuggestions.length > 0) {
                e.preventDefault();
                handleUserSuggestionClick(userSuggestions[selectedSuggestionIndex]);
            }
        }
    };

    const handleUserSuggestionClick = (username: string) => {
        const lastAtSymbolIndex = content.lastIndexOf('@', cursorPosition);
        const newContent = content.slice(0, lastAtSymbolIndex + 1) + username + content.slice(cursorPosition);
        setContent(newContent);
        setShowUserSuggestions(false);
        if (textareaRef.current) {
            const newCursorPosition = lastAtSymbolIndex + username.length + 1;
            textareaRef.current.focus();
            textareaRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
            setCursorPosition(newCursorPosition);
        }
    };

    return (
        <div className="compose-box">
            <textarea
                ref={textareaRef}
                className={`full-width ${isExpanded ? 'expanded' : ''}`}
                value={content}
                onChange={handleTextareaChange}
                onFocus={handleTextareaFocus}
                onKeyDown={handleKeyDown}
                placeholder={placeholder}
            />
            {showUserSuggestions && (
                <div id="user-suggestions" className="user-suggestions">
                    {userSuggestions.map((username, index) => (
                        <div
                            key={index}
                            className={`user-suggestion ${index === selectedSuggestionIndex ? 'selected' : ''}`}
                            onClick={() => handleUserSuggestionClick(username)}
                        >
                            {username}
                        </div>
                    ))}
                </div>
            )}
            {isExpanded && (
                <>
                    {showPreview && (
                        <div className="preview">
                            <h3>Preview:</h3>
                            <ReactMarkdown>{content}</ReactMarkdown>
                        </div>
                    )}
                    <div className="right-align">
                        <button 
                            className="controls" 
                            onClick={handlePost}
                            disabled={isPosting}
                        >
                            {isPosting ? 'Posting...' : 'Post'}
                        </button>
                        <button className="controls" onClick={() => {
                            setIsExpanded(false);
                            setContent('');
                            if (textareaRef.current) {
                                textareaRef.current.style.height = 'auto';
                            }
                            onClose();
                        }}>
                            Discard
                        </button>
                    </div>
                </>
            )}
        </div>
    );
}
