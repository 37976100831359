//////////////////////////////////////////////////////////////////////
//
// These types must be kept in sync with the backend API types
// (backend/src/api.rs).
//

import { useState } from "react";
import { API_BASE_URL } from "../app/common";
import Link from "next/link";
import useSWR from "swr";

// A concept reference, which is a unique identifier for a concept.  Used
// for both general concepts (topics) and specific products.
export interface DisplayConceptRef {
  cref: string;
  display: string;
}

// Provenance information for documents and feed items
export interface Provenance {
  createdon: string;
  creator: string;
}

// A document or post, as displayed to the user.
export interface DisplayDoc {
  key: string;
  text: string;
  topic: DisplayConceptRef;
  aspect: DisplayConceptRef;
  citations: [{
    doc: string;
    rel: string;
  }]
  provenance: Provenance;
  concepts: DisplayMentionedConcept[]
}

// For attaching children to a document node.
export interface DocNode extends DisplayDoc {
  children: DocNode[];
}

// Define type of the json structure we get back from the guide endpoint
export interface GuideJsonResp {
  full: string;
  summary: string;
  full_doc_key: string;
};

// A concept mentioned in a document or post.
export interface DisplayMentionedConcept {
  mention: string;
  dcref: DisplayConceptRef;
  description: string;
  img_url: string;
}

// Define type of the json structure we get back
export interface ProductJsonResp {
  id: string;
  title: string;
  description: string;
  vendor: string;
  image_urls: string[];
  price?: number;
  currency?: string;
  customer_rating?: number;
};

// A thread of posts
export type DisplayThread = {
  docs: DisplayDoc[];
}

// A feed item
export type FeedItem = 
  | { type: 'Thread', value: DisplayThread };

// Info about a follow for a user (typically the current user).  Contains
// the username of the followed user, and the topics for the follow.
export interface DisplayUserFollow {
    username: string;
    topics: DisplayConceptRef[];
}

// Some additional info for the user that's generally useful in the UI.
export interface DisplayUser {
    username: string;
    fullname: string;
    link: string;
    communities: DisplayConceptRef[];
    following: DisplayUserFollow[];
}
export const EmptyDisplayUser: DisplayUser = {
    username: "",  // TODO: is this valid??
    fullname: "",
    link: "",
    communities: [],
    following: [],
};

// A request to join or leave communities.
export interface UpdateUserCommunitiesRequest {
    username: string;
    add_communities?: string[];
    rem_communities?: string[];
}

// A request to follow or unfollow a user.
export interface UpdateUserFollowsRequest {
    username: string;
    follow: string;
    action: "Follow" | "Unfollow" | "Update";
    topics?: string[];
}

//////////////////////////////////////////////////////////////////////
//
// Shared components that call the backend
//

// Shared fetcher with error handling
export const urlFetcher = (url: string) => fetch(url).then(res => {
    if (!res.ok) throw Error(res.statusText);
    return res.json();
});

// Not sure how widely these are used.  Also not sure if they should be
// used vs. the useSWR hook.
export enum LoadStatus {
  NoArg,
  Error,
  IsLoading,
  EmptyResponse,
  Success,
}

// Load a concept definition from the backend.
export function useGetCDef(topic: string): [LoadStatus, Record<string, any> | null] {
  const req_url: string = API_BASE_URL + `/schema/${topic}`;
  const { data, error, isLoading } = useSWR(req_url, urlFetcher);

  if (topic === "") return [LoadStatus.NoArg, null];
  if (error) return [LoadStatus.Error, null];
  if (isLoading) return [LoadStatus.IsLoading, null];
  let full_response = JSON.parse(data);

  let cdef = full_response;
  if (!cdef || cdef.length == 0) return [LoadStatus.EmptyResponse, null];

  return [LoadStatus.Success, cdef];
}


export function UserPosts({ posts }: { posts: any[] }) {
  const [topicDisplayNames, setTopicDisplayNames] = useState<Record<string, string>>({});

  if (!posts || posts.length === 0) return (<div>No posts found</div>);

  return (
    <ul className="no-bullets">
      {posts.map((post: any, index: number) => (
        <li key={index}>
          <Link href={`/doc/${encodeURIComponent(post.key)}`}>
            {(new Date(post.provenance.createdon)).toLocaleDateString()}
          </Link>: 
          topic: 
          <Link href={`/guide/${encodeURIComponent(post.topic.cref)}`}>
            {post.topic.display}
          </Link>
          <br/>{post.text}
        </li>
      ))}
    </ul>
  );
}

export function FollowingList({ following }: { following: DisplayUserFollow[] }) {
  return (
    <ul>
      {following.map((follow, index) => (
        <li key={index}>
          <Link href={`/citizen/${encodeURIComponent(follow.username)}`}>
            {follow.username}

            {follow.topics.length > 0 && (
            /* TODO: make this pretty */
            <div>Topics: {follow.topics.map((c: DisplayConceptRef) => c.display).join(', ')} </div> ) }

          </Link>
        </li>
      ))}
    </ul>
  );
}

//////////////////////////////////////////////////////////////////////
//
// Shared functions for calling the backend
//

export async function updateUserCommunities(
  username: string,
  req_fields: any,
) : Promise<DisplayUser> {
  let request: UpdateUserCommunitiesRequest = {
    username: username,
    ...req_fields,
  };

  let resp = await fetch(API_BASE_URL + "/user/set_communities", {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(request),
  });
  if (!resp.ok) {
    throw new Error("Error modifying community: " + resp.statusText);
  }

  let json_data = await resp.json();
  return json_data;
}

export async function updateUserFollows(
  username: string,
  req_fields: any,
) : Promise<DisplayUser> {
  let request: UpdateUserCommunitiesRequest = {
    username: username,
    ...req_fields,
  };

  let resp = await fetch(API_BASE_URL + "/user/set_follows", {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(request),
  });
  if (!resp.ok) {
    throw new Error("Error modifying follows: " + resp.statusText);
  }

  let json_data = await resp.json();
  return json_data;
}

